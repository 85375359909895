// src/utilities/SubscriptionChecker.js

import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

const checkForActiveSubscription = async (userId) => {
  const subscriptionsRef = collection(db, 'customers', userId, 'subscriptions');
  
  // Include both 'active' and 'trialing' statuses
  const activeSubscriptionsQuery = query(
    subscriptionsRef,
    where('status', 'in', ['active', 'trialing'])
  );
  
  const querySnapshot = await getDocs(activeSubscriptionsQuery);

  if (!querySnapshot.empty) {
    const activeSubscriptions = [];
    querySnapshot.forEach(doc => {
      const subscriptionData = doc.data();
      const startDate = new Date(subscriptionData.current_period_start.seconds * 1000);
      const endDate = new Date(subscriptionData.current_period_end.seconds * 1000);

      activeSubscriptions.push({
        id: doc.id,
        startDate: startDate.toDateString(),
        endDate: endDate.toDateString(),
        status: subscriptionData.status, // Include status for additional checks if needed
      });
    });
    return activeSubscriptions;
  } else {
    return [];
  }
};

export default checkForActiveSubscription;

