import React from 'react';
import { Badge } from './HomeComponents/ui/Badge';
import { Button } from './HomeComponents/ui/Button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './HomeComponents/ui/Card';
import { features } from './HomeData/features';
import { pricing } from './HomeData/pricing';
import { CircleCheck } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './HomeComponents/Navbar';
import Footer from './HomeComponents/Footer';
import ThemeChanger from './HomeComponents/ThemeChanger';
import './styles/tailwind-home.css';
import './styles/global-home.css';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <div className="light">  {/* Force light mode */}
      <Helmet>
        <title>TaxBot - Make Sense of Irish Taxes | AI Tax Assistant</title>
        <meta name="description" content="Get instant answers to your Irish tax questions. TaxBot helps you understand Irish taxes quickly and easily with AI-powered responses." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://taxbot.ie" />
        <meta property="og:title" content="TaxBot - Make Sense of Irish Taxes | AI Tax Assistant" />
        <meta property="og:description" content="Get instant answers to your Irish tax questions. TaxBot helps you understand Irish taxes quickly and easily with AI-powered responses." />
        <meta property="og:image" content="https://taxbot.ie/og-image.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://taxbot.ie" />
        <meta property="twitter:title" content="TaxBot - Make Sense of Irish Taxes | AI Tax Assistant" />
        <meta property="twitter:description" content="Get instant answers to your Irish tax questions. TaxBot helps you understand Irish taxes quickly and easily with AI-powered responses." />
        <meta property="twitter:image" content="https://taxbot.ie/og-image.jpg" />

        {/* Additional SEO meta tags */}
        <meta name="keywords" content="irish tax, tax calculator, tax help, ireland taxes, tax assistant, AI tax help" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://taxbot.ie" />
      </Helmet>

      {/* Navbar */}
      <Navbar />

 {/* Main Section */}
 <div className="border-b border-border">
      <main className="container mx-auto">
        <div className="relative md:mt-24 mx-auto w-full max-w-4xl pt-4 text-center">
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl my-4 font-extrabold md:leading-tight text-black dark:text-white">
        Make sense of Irish taxes
        </h1>
        <p className="mx-auto my-4 w-full max-w-xl text-center font-medium leading-relaxed tracking-wide text-gray-700 dark:text-gray-300 text-base sm:text-lg md:text-xl lg:text-2xl px-4 sm:px-0">
        Get instant responses to questions on how Irish tax works
        </p>

          {/* Button Container */}
          <div className="flex flex-col items-center justify-center gap-8 p-4 text-lg backdrop-blur-lg rounded-md border-transparent">
            <a
              href="/signup"
              className="flex flex-row items-center justify-center font-semibold text-lg px-4 py-2 text-black dark:text-white">
              Get started
              <span className="w-6 h-6 ml-2">
                <CircleCheck className="text-black dark:text-white" />
              </span>
            </a>
          </div>

            {/* Background shapes */}
            <div className="absolute top-0 -z-10 max-h-full max-w-screen-lg w-full h-full blur-2xl">
              <div className="absolute top-15 left-12 w-56 h-56 bg-green-600 rounded-full mix-blend-multiply opacity-60 animate-blob filter blur-3xl"></div>
              <div className="absolute hidden md:block bottom-2 right-1/4 w-56 h-56 bg-green-500 rounded-full mix-blend-multiply opacity-50 animate-blob delay-1000 filter blur-3xl"></div>
              <div className="absolute hidden md:block bottom-1/4 left-1/3 w-56 h-56 bg-yellow-500 rounded-full mix-blend-multiply opacity-55 animate-blob delay-500 filter blur-3xl"></div>
            </div>
          </div>

          {/* Image placeholder with padding */}
          <div className="max-w-4xl mx-auto mb-8 px-4"> {/* Added px-4 for padding on mobile */}
          <video className="w-full" controls poster="/image-placeholder.png">
            <source src="/TaxBot_1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
           </video>
          </div>
        </main>
      </div>

                {/* Featured On Section */}
                <section className="border-b border-border bg-gradient-to-r from-blue-50 via-white to-pink-50 py-16 relative">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-black mb-6">Featured on</h2>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-16">
            {/* Irish Independent Logo */}
            <div className="flex items-center justify-center h-50 w-80 mt-4"> {/* Increased container size */}
              <img
                src="/irish-independent-logo.png"
                alt="Irish Independent Logo"
                className="h-full object-contain"
              />
            </div>
            {/* Newstalk Logo */}
            <div className="flex items-center justify-center h-24 w-48">
              <img
                src="/Newstalk_logo.png"
                alt="Newstalk Logo"
                className="h-full object-contain"
              />
            </div>
          </div>
        </div>
      </section>


    {/* Features Section */}
    <section className="border-b border-border bg-gradient-to-r from-blue-50 via-white to-pink-50 py-16 relative">
      <div className="container mx-auto text-center">
        <div className="my-12">
          <h2 className="text-4xl font-extrabold my-4 text-black">Complicated tax made simple, in seconds</h2>
          <p className="mx-auto my-4 text-sm w-full max-w-md bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-700">
          </p>

          <div className="flex flex-col md:flex-row gap-10 mt-12">
            {features.map((feature) => (
              <Card key={feature.title} className="max-w-sm mx-auto bg-white dark:bg-white text-black p-8 rounded-xl shadow-lg">
                <CardHeader className="text-center">
                  <div className="w-20 h-20 flex justify-center items-center rounded-lg mx-auto">
                    <div className="w-16 h-16 bg-gray-900 dark:bg-gray-900 flex justify-center items-center rounded-lg">
                  <span className="text-white text-xl">
                        {feature.icon}
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="mt-4">
                  <CardTitle className="text-xl font-semibold text-black dark:text-black">{feature.title}</CardTitle>
                  <CardDescription className="mt-2 text-gray-600 dark:text-gray-600">{feature.description}</CardDescription>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>


{/* Pricing Section */}
<section className="border-b border-border bg-gray-900"> {/* Dark background */}
  <div className="container mx-auto text-center">
    <div className="py-14">
      <h2 className="text-4xl font-extrabold my-4 text-white">Pricing</h2>
      <p className="mx-auto my-4 text-sm w-full max-w-md text-center font-medium leading-relaxed tracking-wide text-gray-300">
        Get answers in seconds instead of days.
      </p>

      {/* Added padding on mobile for boxes */}
      <div className="flex flex-col items-center justify-center gap-8 sm:flex-row sm:flex-wrap md:justify-center px-4">
        {pricing.map((plan) => (
          <Card key={plan.title} className="w-full max-w-lg text-left bg-gray-800 text-white p-8 rounded-lg shadow-md relative flex flex-col justify-between h-full">
            {plan.fancy && (
              <Badge className="absolute top-4 right-4 text-sm text-gray-300 bg-gray-700 px-2 py-1 rounded-md">
                Popular
              </Badge>
            )}
            <CardHeader>
              <CardTitle className="text-2xl font-semibold text-black dark:text-white">{plan.title}</CardTitle>
              <CardDescription className="mt-2 text-gray-400">{plan.description}</CardDescription>
              <h5 className="mt-4 text-3xl font-bold text-black dark:text-white">{plan.price}</h5>
            </CardHeader>
            <CardContent className="mt-auto">
              <Link to="/signup">
                <Button className="w-full bg-gray-700 hover:bg-gray-600 text-white py-3 rounded-md mt-6 flex items-center justify-center">
                  {/* Conditional Button Text */}
                  {plan.title === 'Starter' ? 'Sign Up' : plan.title === 'Pro' ? 'Start Free Trial' : 'Sign Up'}
                </Button>
              </Link>
            </CardContent>
            <CardFooter className="mt-4">
              <ul className="text-gray-500 light:text-black">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <CircleCheck className="w-4 h-4 text-green-500" />
                    {feature}
                  </li>
                ))}
              </ul>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  </div>
</section>


      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
