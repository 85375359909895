export const footerLinks = [
    {
      href: '/',
      label: 'Home',
    },
    {
      label: 'Irish Tax Leglislation',
      href: '/tax-full',
    },
    {
      href: '/contact',
      label: 'Contact',
    },
  ];
  