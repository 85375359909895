import React from "react";
import { Link } from "react-router-dom";
import generatedRoutes from "./generatedRoutes.json";
import Navbar from './HomeComponents/Navbar';
import Footer from './HomeComponents/Footer';
import { Helmet } from "react-helmet-async";

const TaxFullDirectory = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Tax Legislation Directory | TaxBot - Irish Tax Resources</title>
        <meta name="description" content="Browse our comprehensive collection of Irish tax documents and resources. Find detailed information about Irish taxation, regulations, and guidelines." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://taxbot.ie/tax-full" />
        <meta property="og:title" content="Tax Document Directory | TaxBot - Irish Tax Resources" />
        <meta property="og:description" content="Browse our comprehensive collection of Irish tax documents and resources. Find detailed information about Irish taxation, regulations, and guidelines." />
        <meta property="og:image" content="https://taxbot.ie/og-image.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://taxbot.ie/tax-full" />
        <meta property="twitter:title" content="Tax Document Directory | TaxBot - Irish Tax Resources" />
        <meta property="twitter:description" content="Browse our comprehensive collection of Irish tax documents and resources. Find detailed information about Irish taxation, regulations, and guidelines." />
        <meta property="twitter:image" content="https://taxbot.ie/og-image.jpg" />

        {/* Additional SEO meta tags */}
        <meta name="keywords" content="irish tax documents, tax resources, ireland taxation, tax guidelines, revenue documents, irish tax library" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://taxbot.ie/tax-full" />
      </Helmet>

      <Navbar />
      {/* Page Header */}
      <div className="bg-gradient-to-r from-green-100 to-green-300 text-center py-16">
        <h1 className="text-4xl font-extrabold text-gray-800 dark:text-white">
            Tax Legislation Directory
        </h1>
        <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
          Explore all available tax documents.
        </p>
      </div>
      {/* Directory List */}
      <main className="flex-grow container mx-auto p-6">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {generatedRoutes.map((doc) => (
            <li key={doc.id} className="border rounded-lg p-4 bg-white shadow-md hover:shadow-lg">
              <Link
                to={`/tax-full/${doc.id}`}
                className="text-blue-500 hover:underline"
              >
                {doc.metadata.reference}
              </Link>
            </li>
          ))}
        </ul>
      </main>
      <Footer />
    </div>
  );
};

export default TaxFullDirectory;