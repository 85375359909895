export const pricing = [
    {
      title: 'Free',
      features: [
        '10 questions per month',
        'Irish tax law citations',
      ],
      description: 'Limited plan test it out',
      fancy: false,
    },
    {
      title: 'Pro',
      price: '€19.99 / month',
      features: [
        'Unlimited questions',
        'Irish tax law citations',
        'Revenue Commissioner citations',
        'Same-day support',
      ],
      description: 'For business owners and professionals.',
      fancy: true, // Marked as a popular plan
    },
  ];
