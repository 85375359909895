import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Navbar from './HomeComponents/Navbar';
import Footer from './HomeComponents/Footer';

const API_URL = "https://taxbot-api-stream-14072143234.europe-west1.run.app/documents";

const DocumentPage = ({ documentId }) => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(API_URL);
        const documents = response.data.documents || [];
        const foundDoc = documents.find((doc) => doc.id === documentId);
        setDocument(foundDoc);
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [documentId]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Helmet>
          <title>Loading... | TaxBot</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Navbar />
        <div className="flex-grow flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!document) {
    return (
      <div className="min-h-screen flex flex-col">
        <Helmet>
          <title>Document Not Found | TaxBot</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Navbar />
        <div className="flex-grow flex items-center justify-center">
          <p className="text-gray-700 text-lg">Document not found.</p>
        </div>
        <Footer />
      </div>
    );
  }

  const { reference, description, text } = document.metadata;
  const textExcerpt = text
    ? `${text.substring(0, 155)}...`
    : description;

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>{`${reference} | TaxBot - Irish Tax Documents`}</title>
        <meta name="description" content={textExcerpt} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://taxbot.ie/tax-full/${documentId}`} />
        <meta property="og:title" content={`${reference} | TaxBot - Irish Tax Documents`} />
        <meta property="og:description" content={textExcerpt} />
        <meta property="og:image" content="https://taxbot.ie/og-image.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://taxbot.ie/tax-full/${documentId}`} />
        <meta property="twitter:title" content={`${reference} | TaxBot - Irish Tax Documents`} />
        <meta property="twitter:description" content={textExcerpt} />
        <meta property="twitter:image" content="https://taxbot.ie/og-image.jpg" />

        {/* Additional SEO meta tags */}
        <meta name="keywords" content={`irish tax, ${reference.toLowerCase()}, tax documents, ireland taxation, revenue documents`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://taxbot.ie/tax-full/${documentId}`} />
      </Helmet>
      {/* Navbar */}
      <Navbar />
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-green-100 to-green-300 text-center py-16">
        <h1 className="text-4xl font-extrabold text-gray-800 dark:text-white">
          {reference}
        </h1>
        <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
          {description}
        </p>
      </div>
      {/* Main Content */}
      <main className="flex-grow p-6 container mx-auto">
        <p className="text-gray-700 dark:text-gray-300">{text || "No content available."}</p>
      </main>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DocumentPage;